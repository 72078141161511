// src/utils/logger.ts

export const logger = (reducer: Function) => {
  return (prevState: any, action: any) => {
    const nextState = reducer(prevState, action);
    console.log(
      "%cPrevious State:",
      "color: #9E9E9E; font-weight: 700;",
      prevState
    );
    console.log("%cAction:", "color: #00A7F7; font-weight: 700;", action);
    console.log(
      "%cNext State:",
      "color: #47B04B; font-weight: 700;",
      nextState
    );
    return nextState;
  };
};
