import * as React from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import axios from "axios";
import { useState } from "react";
import { Alert, List, ListItem, Typography } from "@mui/material";
import { getApiUrl } from "../../utils/isDev";

function Progress() {
  const [progress, setProgress] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  React.useEffect(() => {
    const getProgress = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(getApiUrl() + "/indexer");
        setProgress(response.data);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    getProgress();
    const interval = setInterval(getProgress, 30000);

    return () => clearInterval(interval);
  }, []);

  if (isLoading) {
    return (
      <Box sx={{ width: "100%", height: "20px" }}>
        <LinearProgress color="secondary" />
      </Box>
    );
  }

  if (error) {
    // Display the first error encountered, adjust according to your needs
    //@ts-ignore
    const errorMessage = error.message || error;
    return <div>Error: {errorMessage}</div>;
  }

  // return <Typography>
  //   $DIPE IS MINTED OUT!, TRY DEPLOYING AND MINTING OTHER DIPE PROTOCOL TOKENS
  // </Typography>
  if (progress?.length === 0) {
    return (
      <Box sx={{ width: "100%", height: "20px" }}>
        <Typography>
          $DIPE IS MINTED OUT!, TRY DEPLOYING AND MINTING OTHER DIPE PROTOCOL
          TOKENS
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Alert severity="info">The indexer may be delayed.</Alert>
      <List>
        {progress?.map((item: any) => (
          <ListItem key={item._id}>
            <Typography>
              last indexed {item.name}: {item.lastIndex}
            </Typography>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default React.memo(Progress);
