import React, { createContext, useReducer, useContext, useEffect } from "react";
import { authReducer } from "./Auth.reducer";
import { AuthState } from "./Auth.types";
import { logger } from "../Logger";
import { loadState, saveState } from "../localStorage";

const initialState: AuthState = loadState("auth") || {
  isAuthenticated: false,
  user: null,
  isOpen: false,
};

const AuthContext = createContext<{
  state: AuthState;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

// The AuthProvider component is a wrapper around the children components that
//@ts-ignore
export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(logger(authReducer), initialState);

  useEffect(() => {
    // console.log("AuthContext", state);
    // saveState("auth", state);
  }, [state]);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}{" "}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
