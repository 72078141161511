import React, { Suspense, useState } from "react";
import { theme } from "./theme";
import {
  Alert,
  Box,
  CircularProgress,
  Divider,
  ThemeProvider,
} from "@mui/material";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Footer from "./components/Footer/Footer";
import PrimarySearchAppBar from "./components/AppBar/AppBar.component";
// import Progress from "./components/Progress/Progress.component";
import StatsPage from "./pages/Stats/Stats.page";
import AuthDialog from "./components/Auth/Auth.component";
import TransferDialog from "./components/Transfer/Transfer.component";
import ListingDialog from "./components/Listing/Listing.component";
import ProgressComponent from "./components/Progress/Progress.component";
import BuyDialog from "./components/Buy/Buy.component";

const MintPage = React.lazy(() => import("./pages/Mint/Mint.page"));
const FeedPage = React.lazy(() => import("./pages/Feed/Feed.page"));
const InscriptionPage = React.lazy(
  () => import("./pages/Inscription/Inscription.page")
);
const ProfilePage = React.lazy(() => import("./pages/Profile/Profile.page"));
const MarketPage = React.lazy(() => import("./pages/Market/Market.page"));

function App() {

  //maintenace mode
  // return(
  //   <Box
  //     sx={{
  //       display: "flex",
  //       alignItems: "center",
  //       justifyContent: "center",
  //       height: "100vh",
  //     }}
  //   >
  //     <Alert severity="warning">Site is under maintenance will be back soon</Alert>
  //   </Box>
  // )
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          width: "100vw",
          minHeight: "100vh",
          // overflow: "auto",
          // background: "#242424",
          // display: "flex",
          // flexDirection: "column",
          // alignItems: "center",
          //no overflow
          overflowX: "hidden",
          background:
            "radial-gradient(circle, rgba(133,88,9,1) 0%, rgba(0,0,0,1) 100%, rgba(0,0,0,1) 100%)",
        }}
      >
        <Suspense
          fallback={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}
            >
              <CircularProgress color="secondary" />
            </Box>
          }
        >
          <Router>
            <Box
              sx={{
                width: "100%",
                // maxWidth: "1200px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <PrimarySearchAppBar />
              <Divider variant="middle" sx={{ height: "10rem" }} />
              {/* <Progress /> */}
              {/* <Divider variant="middle" sx={{ height: "5rem" }} /> */}
              <Routes>
                <Route path="/" element={<FeedPage />} />
                {/* <Route path="/feed" element={<FeedPage />} /> */}
                <Route path="/token/:token" element={<MintPage />} />
                <Route path="/stats/:token" element={<StatsPage />} />
                <Route path="/user/:user" element={<ProfilePage />} />
                <Route path="/marketplace/:token" element={<MarketPage />} />
                <Route path="/inscribe" element={<Navigate to="/" replace />} />
              </Routes>
              {/* <Divider variant="middle" sx={{ height: "5rem" }} /> */}
              
              <Divider variant="middle" sx={{ height: "5rem" }} />
              <ProgressComponent />
              <Divider variant="middle" sx={{ height: "5rem" }} />
              <Footer />
            </Box>
            <AuthDialog />
            <TransferDialog />
            <ListingDialog />
            <BuyDialog />
          </Router>
        </Suspense>
        <Toaster position="bottom-right" />
      </Box>
    </ThemeProvider>
  );
}

export default App;
