import { useState, useEffect, SetStateAction } from "react";
import { getApiUrl } from "../utils/isDev";

interface IHolders {
  walletId: string;
  tokens: Record<string, number>;
}
export function useFetchHolders(tokenId: string, limit = 10) {
  const [holders, setActivities] = useState<IHolders[]>([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    async function fetchTokens() {
      try {
        let url = `${getApiUrl()}/api/tokens/${tokenId}/holders?page=${page}&limit=${limit}`;

        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const newTokens: IHolders[] = await response.json();
        setActivities((prev) => [...prev, ...newTokens] as IHolders[]);
        setHasMore(newTokens.length === limit);
      } catch (e) {
        setError(e as SetStateAction<null>);
      } finally {
        setLoading(false);
      }
    }

    fetchTokens();
  }, [page, limit, tokenId]);

  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return { holders, loading, error, hasMore, loadMore, page };
}
