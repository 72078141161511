import * as React from "react";
//@ts-ignore
import dogecoin from "bitcore-lib-doge";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useAuth } from "../../context/Auth/Auth.context";
import { Box, DialogContent, Menu, TextField, useTheme } from "@mui/material";
import { saveState } from "../../context/localStorage";
import { encrypt } from "../../utils/encryption";
import DogeWalletBalance from "../DogeWalletBalance/DogeWalletBalance.component";
import { useNavigate } from "react-router-dom";

function AuthDialog() {
  const theme = useTheme();
  const { state, dispatch } = useAuth();
  const [password, setPassword] = React.useState("");
  const [privateKey, setprivateKey] = React.useState("");

  const navigate = useNavigate();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setprivateKey(e.target.value);
  };

  const handleMenuClose = () => {
    setPassword("");
    setprivateKey("");
  };

  return (
    <Menu
      // anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={state.isOpen}
      onClose={handleMenuClose}
      sx={{ background: "transparent", marginTop: "5vh" }}
      slotProps={{
        paper: {
          sx: {
            border: "1px solid rgba(255, 255, 255, 0.52)",
            backgroundColor: "#000",
            boxShadow:" -5px 5px 10px #fff",
            // boxShadow: "none",
            background: theme.palette.background.default,
            width: {
              xs: "100%",
              sm: "40%",
              md: "40%",
              lg: "40%",
              xl: "40%",
            },
          },
        },
      }}
    >
      {!state.isAuthenticated && (
        <Box
          sx={{
            padding: "2rem",
          }}
        >
          {" "}
          <Typography variant="h6">
            Connect Using Your Wallet Address
          </Typography>{" "}
          <Box m={2} />
          <Box>
            <TextField
              variant="outlined"
              label="Private Key"
              value={privateKey}
              onChange={handleOnChange}
            />
            <Box m={2} />
            <TextField
              type="password"
              variant="outlined"
              label="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <Box m={2} />
            <Box display={"flex"}>
              {" "}
              <Button
                variant="outlined"
                color="secondary"
                onClick={async () => {
                  const pvKey = dogecoin.PrivateKey(privateKey);
                  const address = pvKey.toAddress().toString();
                  let currentUser = {
                    address: address,
                    key: privateKey,
                    isEncrypted: false,
                  };

                  dispatch({
                    type: "LOGIN",
                    payload: {
                      user: currentUser,
                    },
                  });
                  dispatch({
                    type: "CLOSE",
                  });

                  saveState("auth", {
                    ...state,
                    isOpen: false,
                    isAuthenticated: true,
                    user: {
                      ...currentUser,
                      key: await encrypt(currentUser.key, password),
                      isEncrypted: true,
                    },
                  });
                }}
              >
                Connect
              </Button>
              <Box m={1} />
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  dispatch({
                    type: "CLOSE",
                  });
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      {state.isAuthenticated && (
        <Box
          sx={{
            padding: "2rem",
          }}
        >
          {" "}
          <Typography variant="h6">Welcome...</Typography> <Box m={2} />
          <Box>
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              sx={{}}
              onClick={() => {
                dispatch({
                  type: "CLOSE",
                });
                navigate("/user/" + state.user?.address);
              }}
            >
              {state.user?.address}
            </Button>
            <Box m={1} />
            <DogeWalletBalance address={state.user?.address as string} />
            <Box m={2} />
            <Box display={"flex"}>
              <Button
                variant="contained"
                color="warning"
                onClick={() => {
                  dispatch({
                    type: "LOGOUT",
                  });
                  dispatch({
                    type: "CLOSE",
                  });
                  saveState("auth", {});
                  setPassword("");
                  setprivateKey("");
                }}
              >
                LOGout
              </Button>
              <Box m={1} />
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  dispatch({
                    type: "CLOSE",
                  });
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Menu>
  );
}

export default AuthDialog;
