import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./context/Auth/Auth.context";
import "./global.ts";
import { WalletProvider } from "./context/Wallet/Wallet.context";
import { MarketProvider } from "./context/Market/Market.context";

import * as buffer from "buffer";
window.Buffer = buffer.Buffer;
//@ts-ignore
window.buffer = buffer.Buffer;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <AuthProvider>
    <WalletProvider>
      <MarketProvider>
        <App />{" "}
      </MarketProvider>
    </WalletProvider>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
