async function generateKey(password: string | undefined, salt: Uint8Array) {
  const keyMaterial = await window.crypto.subtle.importKey(
    "raw",
    new TextEncoder().encode(password),
    { name: "PBKDF2" },
    false,
    ["deriveKey"]
  );

  return window.crypto.subtle.deriveKey(
    {
      name: "PBKDF2",
      salt: salt,
      iterations: 100000,
      hash: "SHA-256",
    },
    keyMaterial,
    { name: "AES-GCM", length: 256 },
    false,
    ["encrypt", "decrypt"]
  );
}

export async function encrypt(data: string | undefined, password: string | undefined) {
  const salt = window.crypto.getRandomValues(new Uint8Array(16));
  const iv = window.crypto.getRandomValues(new Uint8Array(12));
  const key = await generateKey(password, salt);
  const encodedData = new TextEncoder().encode(data);

  const encryptedData = await window.crypto.subtle.encrypt(
    { name: "AES-GCM", iv: iv },
    key,
    encodedData
  );

  const combinedData = new Uint8Array(
    salt.byteLength + iv.byteLength + encryptedData.byteLength
  );
  combinedData.set(salt, 0);
  combinedData.set(iv, salt.byteLength);
  combinedData.set(
    new Uint8Array(encryptedData),
    salt.byteLength + iv.byteLength
  );

  return btoa(String.fromCharCode.apply(null, combinedData as any));
}

export async function decrypt(encodedData: string, password: string | undefined) {
  const data = Uint8Array.from(atob(encodedData), (c) => c.charCodeAt(0));
  const salt = data.slice(0, 16);
  const iv = data.slice(16, 28);
  const encryptedData = data.slice(28);
  const key = await generateKey(password, salt);

  const decryptedData = await window.crypto.subtle.decrypt(
    { name: "AES-GCM", iv: iv },
    key,
    encryptedData
  );

  return new TextDecoder().decode(decryptedData);
}
