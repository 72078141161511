import React, { createContext, useReducer, useContext, useEffect } from "react";
import { WalletReducer } from "./Wallet.reducer";
import { WalletState } from "./Wallet.types";
import { logger } from "../Logger";
import { loadState, saveState } from "../localStorage";

const initialState: WalletState = loadState("wallet") || {
  error: null,
  loading: false,
  wallet: null,
  transfer: {
    isOpen: false,
    tokenId: null,
  },
  tokens: {},
};

const WalletContext = createContext<{
  state: WalletState;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

// The AuthProvider component is a wrapper around the children components that
//@ts-ignore
export const WalletProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(logger(WalletReducer), initialState);

  useEffect(() => {
    // console.log("WalletContext", state);
    saveState("wallet", {
      ...initialState,
      wallet: initialState.wallet,
    });
  }, [state]);

  return (
    <WalletContext.Provider value={{ state, dispatch }}>
      {children}{" "}
    </WalletContext.Provider>
  );
};

export const useWallet = () => useContext(WalletContext);
