import * as React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { useMarket } from "../../context/Market/Market.context";
import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "axios";

import { useAuth } from "../../context/Auth/Auth.context";
import { decrypt } from "../../utils/encryption";

import { getApiUrl } from "../../utils/isDev";
import { signMessageFromPrivkey } from "../../utils/auth";
import { generateBuyPSBT } from "../../utils/Market";

function BuyDialog() {
  //auth state
  const { state: authState, dispatch: authDispatch } = useAuth();

  const { state, dispatch } = useMarket();
  const theme = useTheme();
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [txid, setTxid] = React.useState("");

  const listing = state.buy.listing;
  console.log(listing, "listing");

  return (
    <Dialog
      open={state.buy.isOpen}
      onClose={() => {
        setError("");
        setTxid("");
      }}
      PaperProps={{
        sx: {
          background: theme.palette.background.default,
          width: {
            xs: "100%",
            sm: "40%",
            md: "40%",
            lg: "40%",
            xl: "40%",
          },
          border: "1px solid rgba(255,255,255,0.2)",
          padding: "1rem",
        },
      }}
    >
      <DialogTitle>Summary</DialogTitle>
      <Box>
        {listing && (
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography variant="body1">listing</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" style={{ color: "rgb(255,162,0)" }}>
                {listing?.amount} {listing?.tokenName}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1">cost</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" style={{ color: "rgb(255,162,0)" }}>
                {listing?.price} doge
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1">fee</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" style={{ color: "rgb(255,162,0)" }}>
                {(listing?.price * 0.015).toFixed(2)}(1.5%) doge
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="h6">Total</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" style={{ color: "rgb(255,162,0)" }}>
                {(listing?.price * 0.015 + listing?.price).toFixed(2)} doge
              </Typography>
            </Grid>
          </Grid>
        )}

        <Box m={2} />
        <TextField
          value={password}
          type="password"
          variant="outlined"
          label="Password"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />

        <Box m={2} />

        <Box m={2} />
        {loading ? (
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            style={{
              width: "100%",
              // background: "red",
            }}
          >
            <Alert severity="info">Please wait...</Alert>
            <CircularProgress color="secondary" />
          </Box>
        ) : (
          txid.length == 0 && (
            <Box display={"flex"}>
              <Button
                variant="contained"
                color="secondary"
                // disabled={!authState.user}
                onClick={async () => {
                  try {
                    if (!listing) throw new Error("Listing not found");
                    setLoading(true);
                    if (!password) throw new Error("Password not found");

                    let decryptedPrivateKey;
                    if (authState?.user?.isEncrypted) {
                      // console.log(2);
                      decryptedPrivateKey = await decrypt(
                        authState.user?.key as any,
                        password
                      );
                      // console.log(3);
                    } else {
                      decryptedPrivateKey = authState.user?.key;
                    }

                    const buyerHex = await generateBuyPSBT(
                      listing,
                      decryptedPrivateKey as any,
                      authState.user?.address as any
                    );
                    console.log(buyerHex, "buyerHex");
                    // return

                    const messageRes = await axios.get(
                      getApiUrl() +
                        "/api/auth/request-message?walletAddress=" +
                        authState.user?.address
                    );
                    console.log(decryptedPrivateKey, "decryptedPrivateKey");
                    const signData = await signMessageFromPrivkey(
                      decryptedPrivateKey as string,
                      messageRes.data.message
                    );

                    const resp = await axios.post(
                      getApiUrl() + "/api/listing/" + listing._id + "/buy",
                      {
                        status: "pending",
                        ...signData,
                        psbt: buyerHex,
                      }
                    );

                    setTxid(resp.data);
                  } catch (e) {
                    console.error(e, "error");
                    setError((e as any).message);
                  } finally {
                    setLoading(false);
                  }
                  console.log("buy");
                }}
              >
                Buy
              </Button>
              <Box m={1} />
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  if (listing) {
                    axios.put(getApiUrl() + "/listing/" + listing._id, {
                      status: "active",
                    });
                  }
                  dispatch({
                    type: "CLEAR_BUY",
                  });
                  window.location.reload();
                }}
              >
                Cancel
              </Button>
            </Box>
          )
        )}
        <Box m={2} />
        {txid && (
          <Button
            variant="text"
            color="secondary"
            onClick={() =>
              window.open("https://sochain.com/tx/DOGE/" + txid, "_blank")
            }
          >
            {txid}
          </Button>
        )}
        {error && <Alert severity="error">{error}</Alert>}
      </Box>

      {/* <Alert severity="error">
        {!authState.user && "Please login to complete this transaction"}
      </Alert> */}
    </Dialog>
  );
}

export default BuyDialog;
