import React from "react";
import { Typography, Container, Paper } from "@mui/material";

const ErrorPage = ({ title, message }: any) => {
  return (
      <Paper
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "80vh",
        //   marginTop: "20vh",
          background: "transparent",
        }}
      >
        <Typography component="h1" variant="h5">
          {title}
        </Typography>
        <Typography variant="subtitle1">{message}</Typography>
      </Paper>
  );
};

export default ErrorPage;
