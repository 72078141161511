import * as bitcoin from "bitcoinjs-lib";
import axios from "axios";
import * as ecpair from "ecpair";
import ecc from "@bitcoinerlab/secp256k1";
import { ITActivity } from "../hooks/UseFetchActivities.hook";
import { getApiUrl } from "./isDev";
import { ITListing } from "../hooks/useFetchListing.hook";
import { calculateFee, getNonDipeUtxos } from "./DipeChain";
const ECPairFactory = ecpair.ECPairFactory;

const network = {
  messagePrefix: "\x19Dogecoin Signed Message:\n",
  bip32: {
    public: 0x02facafd,
    private: 0x02fac398,
  },
  pubKeyHash: 0x1e,
  scriptHash: 0x16,
  wif: 0x9e,
};

const ECPair = ECPairFactory(ecc);

export async function generatePSBTListingInscriptionForSale(
  key: string,
  dipeInput: ITActivity,
  price: number,
  paymentAddress: string
) {
  let psbt = new bitcoin.Psbt({ network: network as bitcoin.Network });

  const res = await axios.get(getApiUrl() + "/rawtx/" + dipeInput.txId);

  const input = {
    ...dipeInput,
    rawTransaction: res.data.result,
  };

  const tx = bitcoin.Transaction.fromHex(input.rawTransaction);
  for (const output in tx.outs) {
    try {
      tx.setWitness(output as any as number, []);
    } catch {}
  }

  psbt.addInput({
    hash: input.txId,
    index: input.index,
    nonWitnessUtxo: tx.toBuffer(),
    // witnessUtxo: tx.outs[ordinalUtxoVout],
    sighashType:
      bitcoin.Transaction.SIGHASH_SINGLE |
      bitcoin.Transaction.SIGHASH_ANYONECANPAY,
  });

  psbt.addOutput({
    address: paymentAddress,
    value: price,
  });

  //   return psbt.toBase64();

  psbt.signInput(0, ECPair.fromWIF(key, network as ecpair.networks.Network), [
    bitcoin.Transaction.SIGHASH_SINGLE |
      bitcoin.Transaction.SIGHASH_ANYONECANPAY,
  ]);
  psbt.finalizeAllInputs();
  const sellerSignedPsbtBase64 = psbt.toBase64();
  return sellerSignedPsbtBase64;
}

const generatePSBTBuyingInscription = async ({
  payerAddress,
  receiverAddress,
  price,
  paymentUtxos,
  sellerSignedPsbt,
}: {
  payerAddress: string;
  receiverAddress: string;
  price: number;
  paymentUtxos: any[];
  sellerSignedPsbt: any | undefined;
}) => {
  const psbt = new bitcoin.Psbt({ network: network as bitcoin.Network });
  let totalValue = 0;
  let totalPaymentValue = 0;

  // // Add dummy utxo input
  // const tx = bitcoin.Transaction.fromHex(await getTxHexById(dummyUtxo.txid));
  // for (const output in tx.outs) {
  //   try {
  //     tx.setWitness(output, []);
  //   } catch {}
  // }
  // psbt.addInput({
  //   hash: dummyUtxo.txid,
  //   index: dummyUtxo.vout,
  //   nonWitnessUtxo: tx.toBuffer(),
  //   // witnessUtxo: tx.outs[dummyUtxo.vout],
  // });

  // // Add inscription output
  // psbt.addOutput({
  //   address: receiverAddress,
  //   value: dummyUtxo.value + Number(inscription["output value"]),
  // });

  // Add payer signed input
  psbt.addInput({
    ...sellerSignedPsbt.data.globalMap.unsignedTx.tx.ins[0],
    ...sellerSignedPsbt.data.inputs[0],
  });
  // Add payer output
  psbt.addOutput({
    ...sellerSignedPsbt.data.globalMap.unsignedTx.tx.outs[0],
  });

  for (const utxo of paymentUtxos) {
    console.log("utxo", utxo);
    const res = await axios.get(getApiUrl() + "/rawtx/" + utxo.txid);
    const tx = bitcoin.Transaction.fromHex(res.data.result);
    for (const output in tx.outs) {
      try {
        tx.setWitness(output as any, []);
      } catch {}
    }

    psbt.addInput({
      hash: utxo.txid,
      index: utxo.vout,
      nonWitnessUtxo: tx.toBuffer(),
      // witnessUtxo: tx.outs[utxo.vout],
    });

    totalValue += utxo.satoshis;
    totalPaymentValue += utxo.satoshis;
  }

  // // Create a new dummy utxo output for the next purchase
  // psbt.addOutput({
  //   address: payerAddress,
  //   value: dummyUtxoValue,
  // });

  const fee = 0.01 * 1e8; //calculateFee(psbt.txInputs.length, psbt.txOutputs.length);

  console.log("fee", fee);
  console.log("totalValue", totalValue);

  ////new caan be removed
  const data = Buffer.from("D T", "ascii");
  const embed = bitcoin.payments.embed({ data: [data] });
  psbt.addOutput({
    script: embed.output as any,
    value: 0,
  });

  psbt.addOutput({
    address: payerAddress,
    value: 0.01 * 1e8,
  });

  ///////

  const changeValue = totalValue - price - fee - 0.01 * 1e8;
  console.log("changeValue", changeValue / 1e8);

  if (changeValue > 0) {
    // Change utxo
    psbt.addOutput({
      address: payerAddress,
      value: changeValue,
    });
  }

  return psbt.toBase64();
};

export async function generateBuyPSBT(
  listing: ITListing,
  buyerKey: string,
  buyerAddress: string
) {
  let salePrice = listing.price * 1e8;

  const sellerPbst = bitcoin.Psbt.fromBase64(listing.psbt, {
    network: network as any,
  });

  // console.log("sellerSignedPsbtBase64", sellerSignedPsbtBase64);

  // Get UTXOs for the buyer
  const utxos = await getNonDipeUtxos(buyerAddress);
  const balance = utxos.reduce(
    (acc: any, utxo: { satoshis: any }) => acc + utxo.satoshis,
    0
  );

  if (balance < listing.price * 1e8) {
    throw new Error("Insufficient funds");
  }
  // //remove duplicate utxos
  // const paymentUtxos = buyerUtxos.filter(
  //   (utxo) => !sellerUtxos.some((sUtxo) => sUtxo.txid === utxo.txid)
  // );
  // sellerPbst.finalizeAllInputs(0);

  const buyerPbstBase64 = await generatePSBTBuyingInscription({
    payerAddress: buyerAddress,
    receiverAddress: buyerAddress,
    price: salePrice,
    paymentUtxos: utxos,
    sellerSignedPsbt: sellerPbst,
  });
  // console.log("buyerPbstBase64", buyerPbstBase64);
  const buyerPbst = bitcoin.Psbt.fromBase64(buyerPbstBase64, {
    network: network as any,
  });

  //sign all inputs except the first one
  for (let i = 1; i < buyerPbst.txInputs.length; i++) {
    buyerPbst.signInput(i, ECPair.fromWIF(buyerKey, network as any));
    console.log("signing input", i);
    buyerPbst.finalizeInput(i);
  }
  // console.log("buyerPbst", buyerPbst);
  // // console.log("output", buyerPbst.txOutputs);
  const txHex = buyerPbst.extractTransaction().toHex();
  return txHex;
}
