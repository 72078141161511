import { WalletState, WalletAction } from "./Wallet.types";

export const WalletReducer = (
  state: WalletState,
  action: WalletAction
): WalletState => {
  console.log(action);
  switch (action.type) {
    case "SET_WALLET":
      return {
        ...state,
        wallet: action.payload,
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "SET_ERROR":
      return {
        ...state,
        error: action.payload,
      };
    case "SET_TRANSFER":
      return {
        ...state,
        transfer: action.payload,
      };
    case "SET_TOKENS":
      return {
        ...state,
        tokens: {
          ...state.tokens,
          [action.payload.page]: action.payload.activities,
        },
      };
    case "REMOVE_TOKEN":
      //find the token by finding the key of where the token id is
      const key = Object.keys(state.tokens).find((page: any) => {
        return state.tokens[page].some(
          (token) => token._id === action.payload.tokenId
        );
      });
      if (key) {
        return {
          ...state,
          tokens: {
            ...state.tokens,
            [key]: state.tokens[key as any].filter(
              (token) => token._id !== action.payload.tokenId
            ),
          },
        };
      }
      return state;
    default:
      return state;
  }
};
