import * as React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { useMarket } from "../../context/Market/Market.context";
import {
  Alert,
  Box,
  CircularProgress,
  FilledInput,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "axios";

import { useAuth } from "../../context/Auth/Auth.context";
import { decrypt } from "../../utils/encryption";

import { getApiUrl } from "../../utils/isDev";
import { generatePSBTListingInscriptionForSale } from "../../utils/Market";
import { signMessageFromPrivkey } from "../../utils/auth";
import toast from "react-hot-toast";
import { useWallet } from "../../context/Wallet/Wallet.context";

function ListingDialog() {
  //auth state
  const { state: authState, dispatch: authDispatch } = useAuth();
  const {dispatch: walletDispatch} = useWallet();
  const { state, dispatch } = useMarket();
  const theme = useTheme();
  const [amount, setAmount] = React.useState(0);
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [txid, setTxid] = React.useState("");

  const token = {};
  if (!state.listing.tokenId) return null;

  return (
    <Dialog
      open={state.listing.isOpen}
      PaperProps={{
        sx: {
          background: theme.palette.background.default,
          width: {
            xs: "100%",
            sm: "40%",
            md: "40%",
            lg: "40%",
            xl: "40%",
          },
          border: "1px solid rgba(255,255,255,0.2)",
          padding: "1rem",
        },
      }}
    >
      <DialogTitle>
        Listing: {state.listing.tokenId.total} {state.listing.tokenId.token}
      </DialogTitle>
      <Box>
        {/* <TextField
          value={totalToken}
          type="text"
          variant="outlined"
          label={`number of ${state.listing.tokenId.token} tokens`}
          onChange={(e) => {
            setTotalTokens(e.target.value);
          }}
        /> */}
        <Typography>
          Please enter the listing price for {state.listing.tokenId.total}{" "}
          {state.listing.tokenId.token}
        </Typography>
        <Box m={2} />
        <FilledInput
          type="number"
          endAdornment={<InputAdornment position="end">Doge</InputAdornment>}
          sx={{
            border: "1px solid rgba(255,255,255,1)",
            width: "100%",
          }}
          inputProps={{
            sx: {
              color: "white",

              textAlign: "center",
            },
          }}
          onChange={(e) => {
            setAmount(e.target.value as any);
          }}
          value={amount}
        />
        <Box m={2} />
        <TextField
          value={password}
          type="password"
          variant="outlined"
          label="Password"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />

        <Box m={2} />

        {error && !txid && <Alert severity="error">{error}</Alert>}
        <Box m={2} />
        {loading ? (
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            style={{
              width: "100%",
              // background: "red",
            }}
          >
            <Alert severity="info">Please wait...</Alert>
            <CircularProgress color="secondary" />
          </Box>
        ) : (
          <Box display={"flex"}>
            <Button
              variant="contained"
              color="secondary"
              disabled={!authState.user}
              onClick={async () => {
                try {
                  setLoading(true);
                  if (!amount) throw new Error("Amount not found");
                  if (!password) throw new Error("Password not found");
                  if (!token) throw new Error("Token not found");
                  if ((token as unknown as number) < amount)
                    throw new Error("Insufficient balance");
                  if (!state.listing.tokenId)
                    throw new Error("Token not found");
                  console.log(1);
                  let decryptedPrivateKey;
                  if (authState?.user?.isEncrypted) {
                    console.log(2);
                    decryptedPrivateKey = await decrypt(
                      authState.user?.key as any,
                      password
                    );
                    console.log(3);
                  } else {
                    decryptedPrivateKey = authState.user?.key;
                  }
                  if (!decryptedPrivateKey) throw new Error("Invalid password");
                  const listingPsbt =
                    await generatePSBTListingInscriptionForSale(
                      decryptedPrivateKey,
                      state.listing.tokenId,
                      amount * 1e8,
                      authState.user?.address as any
                    );
                  console.log(listingPsbt, "listingPsbt");

                  const messageRes = await axios.get(
                    getApiUrl() +
                      "/api/auth/request-message?walletAddress=" +
                      authState.user?.address
                  );
                  const signData = await signMessageFromPrivkey(
                    decryptedPrivateKey as string,
                    messageRes.data.message
                  );
                  // return;

                  // const tokenData = `D T ${state.listing.tokenId} ${totalToken}`;
                  // const salePrice = amount * 1e8; // 6 DOGE

                  const res = await axios.post(getApiUrl() + "/api/list", {
                    price: amount,
                    event: state.listing.tokenId._id,
                    psbt: listingPsbt,
                    sellerAddress: authState.user?.address,
                    ...signData,
                  });
                  dispatch({
                    type: "SET_LISTING",
                    payload: {
                      isOpen: false,
                      tokenId: "",
                    },
                  });
                  walletDispatch({
                    type: "REMOVE_TOKEN",
                    payload: {
                      tokenId: state.listing.tokenId._id,
                    },
                  });
                  
                  toast.success("Successfully listed");
                  // setTxid(
                  //   "successfully listed " + JSON.stringify(res.data, null, 2)
                  // );
                } catch (e) {
                  console.log(e, "error");
                  setError((e as any).message);
                } finally {
                  setLoading(false);
                }
                console.log("transfer");
              }}
            >
              List
            </Button>
            <Box m={1} />
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                dispatch({
                  type: "SET_LISTING",
                  payload: {
                    isOpen: false,
                    tokenId: "",
                  },
                });
              }}
            >
              Cancel
            </Button>
          </Box>
        )}
        <Box m={2} />
        {txid && (
          <Button
            variant="text"
            color="secondary"
            onClick={() =>
              window.open("https://sochain.com/tx/DOGE/" + txid, "_blank")
            }
          >
            {txid}
          </Button>
        )}
      </Box>
    </Dialog>
  );
}

export default ListingDialog;
