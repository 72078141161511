import React, { createContext, useReducer, useContext, useEffect } from "react";
import { MarketReducer } from "./Market.reducer";
import { MarketState } from "./Market.types";
import { logger } from "../Logger";
import { loadState, saveState } from "../localStorage";

const initialState: MarketState = loadState("Market") || {
  error: null,
  loading: false,
  listings: [],
  listing: {
    isOpen: false,
    tokenId: null,
  },
  buy: {
    isOpen: false,
    tokenId: "",
  },
};

const MarketContext = createContext<{
  state: MarketState;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

// The AuthProvider component is a wrapper around the children components that
//@ts-ignore
export const MarketProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(logger(MarketReducer), initialState);

  useEffect(() => {
    // if(state.l)
    // // console.log("MarketContext", state);
    // saveState("Market", state);
    //save everything except the listing
    let { listing, ...rest } = state;
    // saveState("Market", { ...rest, listing: initialState.listing });
  }, [state]);

  return (
    <MarketContext.Provider value={{ state, dispatch }}>
      {children}{" "}
    </MarketContext.Provider>
  );
};

export const useMarket = () => useContext(MarketContext);
