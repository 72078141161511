import * as bitcoin from "bitcoinjs-lib";
import axios from "axios";
import * as ecpair from "ecpair";
import ecc from "@bitcoinerlab/secp256k1";
import bitcoinMessage from "bitcoinjs-message";
import network from "./network";
const ECPairFactory = ecpair.ECPairFactory;

function getDogecoinAddress(publicKey: Buffer, compressed = true) {
  const ECPair = ECPairFactory(ecc);

  const pubkey = ECPair.fromPublicKey(publicKey, {
    compressed: compressed,
  }).publicKey;
  let { address: dogeCoinAddress } = bitcoin.payments.p2pkh({
    pubkey: pubkey,
    network: network as any,
  });
  return dogeCoinAddress;
}

export function signMessageFromPrivkey(privkey: string, message: string) {
  let compressed = privkey.length === 52;
  const ECPair = ECPairFactory(ecc);

  let keyPair = ECPair.fromWIF(privkey, network as any);
  let privateKey = keyPair.privateKey;
  let signature = bitcoinMessage.sign(
    message,
    privateKey as any,
    compressed,
    "\x19Dogecoin Signed Message:\n"
  );

  return {
    signature: signature.toString("base64"),
    walletAddress: getDogecoinAddress(keyPair.publicKey, compressed),
    message,
  };
}
