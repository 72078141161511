import axios from "axios";
//@ts-ignore
import dogecoin from "bitcore-lib-doge";
import { getApiUrl } from "./isDev";
// import { TatumSDK, Dogecoin, Network } from "@tatumio/tatum";

const unspentApi = axios.create({
  baseURL: "https://unspent.dogeord.io/api/v1/address/unspent/",
  timeout: 100_000,
});

export async function getNonDipeUtxos(address: string) {
  const response = await unspentApi.get(`/${address}`);
  const outputs = response.data.unspent_outputs;
  const mappedOutputs = outputs.map(
    (output: {
      tx_hash: any;
      tx_output_n: any;
      script: any;
      value: any;
      address: any;
    }) => ({
      txid: output.tx_hash,
      vout: output.tx_output_n,
      script: output.script,
      satoshis: Number(output.value),
      address: output.address,
    })
  );
  return mappedOutputs.filter(
    (output: { satoshis: Number }) => output.satoshis != 0.01 * 1e8
  );
}

function estimateDogeTransactionSize(numInputs: number, numOutputs: number) {
  const bytesPerInput = 150; // Average size in bytes of an input
  const bytesPerOutput = 34; // Average size in bytes of an output
  const baseTransactionSize = 10; // Base size of a transaction

  // Calculate total size in bytes
  const totalSizeInBytes =
    baseTransactionSize +
    numInputs * bytesPerInput +
    numOutputs * bytesPerOutput;

  // Convert bytes to kilobytes
  const sizeInKilobytes = totalSizeInBytes / 1024;

  return sizeInKilobytes;
}
export const calculateFee = (feeRate: number, outputCount = 3) => {
  const fee = estimateDogeTransactionSize(1, outputCount) * feeRate;
  return fee;
};

export async function broadcastTransaction(transactionHex: any) {
  let broadcasted = false;
  let attempts = 0;
  let txid = null;
  // try {
  //   const tatum = await TatumSDK.init<Dogecoin>({
  //     network: Network.DOGECOIN,
  //     verbose: true,
  //     retryDelay: 1000,
  //     retryCount: 2,
  //   });
  //   const result = await tatum.rpc.sendRawTransaction(transactionHex);
  //   if (result.result) return result.result;

  // } catch (error) {}
  while (!broadcasted && attempts < 4) {
    try {
      const response = await axios.post(getApiUrl() + "/node", {
        tx_hex: transactionHex,
      });
      console.log("Transaction broadcasted with Chain.so");
      txid = response.data;
      broadcasted = true;
    } catch (error: any) {
      console.error(
        "Error broadcasting transaction with BlockCypher:",
        error.response.data
      );
      attempts++;

      if (attempts < 4) {
        try {
          const response = await axios.post(
            "https://api.blockcypher.com/v1/doge/main/txs/push",
            {
              tx: transactionHex,
            }
          );
          console.log("Transaction broadcasted with BlockCypher");
          txid = response.data.tx.hash;
          broadcasted = true;
        } catch (error: any) {
          console.error(
            "Error broadcasting transaction with Chain.so:",
            error.response.data
          );
          attempts++;
        }
      }
    }
  }

  if (!broadcasted) {
    console.log("Failed to broadcast transaction after 4 attempts");
  }

  return txid;
}

const addTransactionToIndexer = async ({
  txHash,
  address,
  token,
  action,
  key,
  total,
}: any) => {
  try {
    await axios.post(getApiUrl() + "/transaction", {
      txHash,
      address,
      token,
      action,
      key,
      total,
    });
  } catch (error: any) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error("Error Response:", error.response.data);
      console.error("Status:", error.response.status);
    } else if (error.request) {
      // The request was made but no response was received
      console.error("Error Request:", error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("Error", error.message);
    }
    console.error("Error config:", error.config);
  }
};

class DipeChain {
  receiver: string;
  serviceProvider: string;
  serviceFee: number;
  decimals: number;
  data: string;
  constructor(receiver: string, data: string) {
    this.receiver = receiver;
    this.data = data;
    ////
    this.serviceProvider = "DKMERWVr3vKhxea2tTe6XFacs6nvs7NcDG";
    this.serviceFee = 2;
    this.decimals = 100000000;
  }

  generateWallet() {
    const node = new dogecoin.PrivateKey();

    console.log(`

Wallet generated: 

- Address : ${node.toAddress()},
- Key : ${node.toWIF()},

`);

    return {
      address: node.toAddress(),
      key: node.toWIF(),
    };
  }

  toUnsignedInteger(num: number) {
    return num >>> 0;
  }

  sleep = (ms: number) => {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  };

  inscribe = async (key: any, fundingUtxos: any, index = 0) => {
    console.log("inscriberUtxos Utxos", fundingUtxos);

    let opReturnData = this.data;

    let transaction = {
      utxos: fundingUtxos.utxos[index],
      address: fundingUtxos.utxos[index].address,
      key,
      opReturnData,
      receiver: this.receiver,
    };
    const response = await axios.post(getApiUrl() + "/inscribe", transaction);

    const txid = await broadcastTransaction(response.data);
    console.log("Broadcasted transaction ID:", txid);
    try {
      let [type, action, token, total] = opReturnData.split(" ");
      //if i doesnt have all the data
      if (!type || !action || !token || !total) {
        return txid;
      }

      if (type == "D" && action == "M") {
        addTransactionToIndexer({
          txHash: txid,
          address: transaction.address,
          token: token,
          action: "Mint",
          key,
          total,
        });
      }
      if (type == "D" && action == "D") {
        addTransactionToIndexer({
          txHash: txid,
          address: transaction.address,
          token: token,
          action: "Deploy",
          key,
          total,
        });
      }
      if (type == "D" && action == "T") {
        addTransactionToIndexer({
          txHash: txid,
          address: transaction.address,
          token: token,
          action: "Transfer",
          key,
          total,
        });
      }

      // if (type == "D" && action == "M" && ticker == "dipe") {
      //   axios.post(
      //     "https://dipe-chain-api-bf9e112f1ffc.herokuapp.com/transfer",
      //     {
      //       txHash: txid,
      //       address: transaction.address,
      //       token: ticker,
      //       action: "Mint",
      //       key,
      //     }
      //   );
      // }
    } catch (error) {
      console.log(error);
    }
    return txid;
  };
}

export default DipeChain;
