import { ITListing } from "../../hooks/useFetchListing.hook";
import { MarketState, MarketAction } from "./Market.types";

export const MarketReducer = (
  state: MarketState,
  action: MarketAction
): MarketState => {
  console.log(action);
  switch (action.type) {
    case "ADD_LISTING":
      return {
        ...state,
        // listings: [...state.listings, action.payload],
      };
    case "DELETE_LISTING":
      return {
        ...state,
        listings: state.listings.filter((item) => item._id !== action.payload._id),
      };
    case "CLEAR_LISTING":
      return {
        ...state,
        listings: [],
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "SET_ERROR":
      return {
        ...state,
        error: action.payload,
      };
    case "SET_LISTING":
      return {
        ...state,
        listing: action.payload,
      };
    case "SET_NEW_LISTINGS":
      let newlistings: ITListing[] = [];
      //remove duplicates
      action.payload.forEach((listing: ITListing) => {
        if (!state.listings.find((item) => item._id === listing._id)) {
          newlistings.push(listing);
        }
      });
      return {
        ...state,
        listings: state.listings.concat(newlistings),
      };
    case "SET_BUY":
      return {
        ...state,
        buy: action.payload,
      };
    case "CLEAR_BUY":
      return {
        ...state,
        buy: {
          isOpen: false,
          tokenId: "",
          listing: undefined,
        },
      };
    default:
      return state;
  }
};
