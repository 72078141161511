// src/utils/storage.ts

export const saveState = (key: string, state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (err) {
    // Handle write errors.
    throw new Error("Error saving state to localStorage");
  }
};

export const loadState = (key: string) => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined; // No state in localStorage
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined; // Errors could happen when state is invalid
  }
};
