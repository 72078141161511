import * as bitcoin from "bitcoinjs-lib";
import axios from "axios";
import * as ecpair from "ecpair";
import ecc from "@bitcoinerlab/secp256k1";
import { ITActivity } from "../../../hooks/UseFetchActivities.hook";
import network from "../../network";
import { getApiUrl } from "../../isDev";
import { getNonDipeUtxos } from "../../DipeChain";

const ECPairFactory = ecpair.ECPairFactory;

interface TransferTokenProps {
  senderKey: string;
  senderAddress: string;
  receiverAddress: string;
  activity: ITActivity;
}

export default async function TransferToken(transferData: TransferTokenProps) {
  try {
    const { senderKey, senderAddress, receiverAddress, activity } =
      transferData;
    const ECPair = ECPairFactory(ecc);
    const keyPair = ECPair.fromWIF(senderKey, network as any);
    const psbt = new bitcoin.Psbt({
      network: network as typeof bitcoin.networks.bitcoin,
    });

    const res = await axios.get(getApiUrl() + "/rawtx/" + activity.txId);
    psbt.addInput({
      hash: activity.txId,
      index: activity.index,
      nonWitnessUtxo: Buffer.from(res.data.result, "hex"),
    });

    const utxos = await getNonDipeUtxos(senderAddress);
    console.log(utxos);

    const utxosTotal = utxos.reduce(
      (acc: any, utxo: { satoshis: any }) => acc + utxo.satoshis,
      0
    );
    console.log("utxosTotal", utxosTotal / 1e8);

    if (utxosTotal < 1 * 1e8) {
      throw new Error("Insufficient funds");
    }

    for (const utxo of utxos) {
      const res = await axios.get(getApiUrl() + "/rawtx/" + utxo.txid);
      psbt.addInput({
        hash: utxo.txid,
        index: utxo.vout,

        // nonWitnessUtxo: Buffer.from(utxo.hex, "hex"),
        nonWitnessUtxo: Buffer.from(res.data.result, "hex"),
      });
    }

    const data = Buffer.from(`D T`);
    const embed = bitcoin.payments.embed({ data: [data] });
    psbt.addOutput({
      script: embed.output as Buffer,
      value: 0,
    });

    psbt.addOutput({
      address: receiverAddress,
      value: 0.01 * 1e8,
    });

    const changeAmount = utxosTotal - 0.05 * 1e8;

    if (changeAmount > 0) {
      psbt.addOutput({
        address: senderAddress,
        value: changeAmount,
      });
    }

    // psbt.signAllInputs(keyPair);
    for (let i = 0; i < utxos.length + 1; i++) {
      psbt.signInput(i, keyPair);
    }

    psbt.finalizeAllInputs();

    const txHex = psbt.extractTransaction(true).toHex();

    const result = await axios.post(getApiUrl() + "/node", {
      tx_hex: txHex,
    });
    return result.data;
  } catch (error) {
    throw new Error("Error in TransferToken" + error);
  }
}
