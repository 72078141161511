import * as React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { useWallet } from "../../context/Wallet/Wallet.context";
import {
  Alert,
  Box,
  CircularProgress,
  FilledInput,
  InputAdornment,
  TextField,
  useTheme,
} from "@mui/material";
import axios from "axios";
import rateLimit from "axios-rate-limit";

// import * as ecc from "tiny-secp256k1";
import { ECPairFactory } from "ecpair";
//@ts-ignore
import dogecoin from "bitcore-lib-doge";
import { shortenString } from "../Feed/Feed.component";

import { useAuth } from "../../context/Auth/Auth.context";
import { decrypt } from "../../utils/encryption";

import { Psbt, Signer } from "bitcoinjs-lib";
import { getApiUrl } from "../../utils/isDev";
import { fetchAllUnspentOutputs } from "../../utils/balance";
import { ITActivity } from "../../hooks/UseFetchActivities.hook";
import TransferToken from "../../utils/Operations/Token/Transfer";

const NETWORK = {
  messagePrefix: "\x19Dogecoin Signed Message:\n",
  bech32: "doge",
  bip32: {
    public: 0x02facafd,
    private: 0x02fac398,
  },
  pubKeyHash: 0x1e,
  scriptHash: 0x16,
  wif: 0x9e,
};

const gasFee = 0.01 * 1e8;

// Create an axios instance with rate limiting
const http = rateLimit(axios.create(), {
  maxRequests: 3,
  perMilliseconds: 1000, // 1000 milliseconds = 1 second
});

function TransferDialog() {
  //auth state
  const { state: authState, dispatch: authDispatch } = useAuth();

  const { state, dispatch } = useWallet();
  const theme = useTheme();
  const [receiver, setReceiver] = React.useState("");
  const [amount, setAmount] = React.useState(0);
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [txid, setTxid] = React.useState("");

  const token = state.wallet?.tokens[state.transfer.tokenId as any];

  return (
    <Dialog
      open={state.transfer.isOpen}
      PaperProps={{
        sx: {
          width: {
            xs: "100%",
            sm: "40%",
            md: "40%",
            lg: "40%",
            xl: "40%",
          },
        },
      }}
    >
      <DialogTitle>
        Transfer: {state.transfer.tokenId?.total}{" "}
        {state.transfer.tokenId?.token}
      </DialogTitle>
      <Box>
        <TextField
          value={receiver}
          type="text"
          variant="outlined"
          label="Recipient's Address"
          onChange={(e) => {
            setReceiver(e.target.value);
          }}
        />

        <Box m={2} />
        <TextField
          value={password}
          type="password"
          variant="outlined"
          label="Password"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />

        <Box m={2} />
        <Alert severity="info">
          there will be a small fee of about 0.03 DOGE for gas and the transfer
        </Alert>
        {error && !txid && <Alert severity="error">{error}</Alert>}
        <Box m={2} />
        {loading ? (
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            style={{
              width: "100%",
              // background: "red",
            }}
          >
            <Alert severity="info">Please wait...</Alert>
            <CircularProgress color="secondary" />
          </Box>
        ) : (
          <Box display={"flex"}>
            <Button
              variant="contained"
              color="secondary"
              disabled={!authState.user}
              onClick={async () => {
                try {
                  setLoading(true);
                  if (!receiver) throw new Error("Receiver address not found");
                  // if (!amount) throw new Error("Amount not found");
                  if (!password) throw new Error("Password not found");
                  // if (!token) throw new Error("Token not found");
                  // if ((token as unknown as number) < amount)
                    // throw new Error("Insufficient balance");
                  console.log(1);
                  let decryptedPrivateKey;
                  if (authState?.user?.isEncrypted) {
                    console.log(2);
                    decryptedPrivateKey = await decrypt(
                      authState.user?.key as any,
                      password
                    );
                    console.log(3);
                  } else {
                    decryptedPrivateKey = authState.user?.key;
                  }
                  if (!decryptedPrivateKey)
                    throw new Error("Decryption failed");
                  const txId = await TransferToken({
                    senderKey: decryptedPrivateKey,
                    senderAddress: authState.user?.address as string,
                    receiverAddress: receiver,
                    activity: state.transfer.tokenId as ITActivity,
                  });
                  setTxid(txId);
                } catch (e) {
                  console.error(e, "error");
                  setError((e as any).message);
                } finally {
                  setLoading(false);
                }
                console.log("transfer");
              }}
            >
              Transfer
            </Button>
            <Box m={1} />
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                dispatch({
                  type: "SET_TRANSFER",
                  payload: {
                    isOpen: false,
                    tokenId: "",
                  },
                });
              }}
            >
              Cancel
            </Button>
          </Box>
        )}
        <Box m={2} />
        {txid && (
          <Button
            variant="text"
            color="secondary"
            onClick={() =>
              window.open("https://sochain.com/tx/DOGE/" + txid, "_blank")
            }
          >
            Successfully broadcasted: {shortenString(txid, 10)}
          </Button>
        )}
      </Box>
    </Dialog>
  );
}

export default TransferDialog;
