import React from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import { useFetchHolders } from "../../hooks/UseFetchHolders.hook";
import { Link } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "rgba(255,255,255,0.5)",
  },
  [`&.${tableCellClasses.body}`]: {
    color: "rgba(255,255,255,0.5)",
  },
}));

const Holders = ({ tokenId }: { tokenId: string }) => {
  const { holders, loading, error, hasMore, loadMore, page } =
    useFetchHolders(tokenId);
  const navigate = useNavigate();

  if (loading) return <div>Loading...</div>;

  if (error && !holders) return <div>Error</div>;

  return (
    <Box
      sx={{
        backgroundColor: "rgba(11,8,1,1)",
        // padding: "3rem 1.5rem",
        width: "100%",
        borderRadius: "20px",
      }}
    >
      <TableContainer
        component={Paper}
        sx={{
          backgroundColor: "rgba(11,8,1,1)",
          color: "rgba(255,255,255,0.5)",
        }}
      >
        <Table
          sx={{ minWidth: 650, color: "rgba(255,255,255,0.5)" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">rank</StyledTableCell>
              <StyledTableCell align="left">wallet</StyledTableCell>
              <StyledTableCell align="left">balance</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {holders.map((holder, i) => {
              return (
                <TableRow
                  key={holder.walletId}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    borderBottom: "2px solid rgba(11,8,1,1)",
                  }}
                >
                  <StyledTableCell component="th" scope="row">
                    {i}
                  </StyledTableCell>

                  <StyledTableCell align="left">
                    <Button
                      variant="text"
                      color="inherit"
                      // to="DKTwt6BwET625YMo28iSENpq57J92JbCdw"
                      onClick={() => {
                        navigate(`/user/${holder.walletId}`);
                      }}
                    >
                      {holder.walletId}
                    </Button>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {holder.tokens[tokenId]}
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default React.memo(Holders);
