import { useState, useEffect, SetStateAction, useRef } from "react";
import { getApiUrl } from "../utils/isDev";

export interface IToken {
  _id: string;
  blockHash: string;
  time: number;
  blockTime: number;
  type: string;
  action: string;
  token: string;
  decimal: number;
  txid: string;
  deployer: string;
  minted: number;
  supply: number;
  limit: number;
  __v: number;
}


export function useFetchTokens(limit = 10) {
  const [tokens, setTokens] = useState<IToken[]>([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<null | string>(null);
  const cache = useRef<Record<number, IToken[]>>({});

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage((prev) => prev - 1);
    }
  };

  const handleNextPage = () => {
    if (hasMore) {
      setPage((prev) => prev + 1);
    }
  };

  useEffect(() => {
    setLoading(true);
    async function fetchTokens() {
      if (cache.current[page]) {
        setTokens(cache.current[page]);
        setHasMore(cache.current[page].length === limit);
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `${getApiUrl()}/api/tokens?page=${page}&limit=${limit}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const newTokens: IToken[] = await response.json();
        cache.current[page] = newTokens;
        setTokens(newTokens);
        setHasMore(newTokens.length === limit);
      } catch (e) {
        setError((e as Error).message);
      } finally {
        setLoading(false);
      }
    }

    fetchTokens();
  }, [page, limit]);

  return {
    tokens,
    loading,
    error,
    hasMore,
    page,
    handlePreviousPage,
    handleNextPage,
  };
}

