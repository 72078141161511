import React, { useState, useEffect } from "react";
import axios from "axios";
import { Skeleton, Typography, useTheme } from "@mui/material";
import { getApiUrl } from "../../utils/isDev";

interface DogeWalletBalanceProps {
  address: string;
}

const DogeWalletBalance: React.FC<DogeWalletBalanceProps> = ({ address }) => {
  const [balanceData, setBalance] = useState<{
    balance: number;
    type: string;
    asset: string;
  } | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const theme = useTheme();

  useEffect(() => {
    const fetchBalance = async () => {
      setIsLoading(true);
      setError(null);
      try {
        // Replace with the actual API endpoint and key if needed
        const response = await axios.get(
          `${getApiUrl()}/balance/${address}`
        );
        setBalance(response.data.data.find((i: any) => i.type === "native"));
      } catch (error) {
        console.error("Error fetching Dogecoin balance:", error);
        setError("Error fetching balance");
      } finally {
        setIsLoading(false);
      }
    };

    fetchBalance();
  }, [address]);

  return (
    <div>
      {isLoading ? (
        <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
      ) : error ? (
        <p style={{ color: "red" }}>{error}</p>
      ) : (
        <Typography sx={{ color: theme.palette.secondary.main }}>
          Balance: {balanceData?.balance} {balanceData?.asset}
        </Typography>
      )}
    </div>
  );
};

export default DogeWalletBalance;
