import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  LinearProgress,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTheme } from "@mui/material/styles";

import React, { useEffect, useState } from "react";
// import data from "./blockchainData.json";
import { json } from "stream/consumers";
import { useNavigate } from "react-router-dom";
import ErrorPage from "../Error/Error.component";
import { useFetchTokens } from "../../hooks/useFetchTokens.hook";

export const shortenString = (str: string, frontChars = 6, backChars = 4) => {
  return `${str.substring(0, frontChars)}...${str.substring(
    str.length - backChars
  )}`;
};

function shortenNumber(num: number) {
  if (num < 1000) {
    return num; // return the number if it's less than 1000
  }
  if (num < 1000000) {
    return (num / 1000).toFixed(1) + "k"; // convert to 'k' for thousands
  }
  if (num < 1000000000) {
    return (num / 1000000).toFixed(1) + "M"; // convert to 'M' for millions
  }
  return (num / 1000000000).toFixed(1) + "B"; // convert to 'B' for billions
}

function LinearWithValueLabel({
  balance,
  supply,
}: {
  balance: number;
  supply: number;
}) {
  const [progress, setProgress] = React.useState((balance / supply) * 100);

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">
            {parseInt(progress.toString())}%
          </Typography>
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">
            {shortenNumber(balance)} / {shortenNumber(supply)}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%" }}>
          <LinearProgress
            color="secondary"
            variant="determinate"
            value={progress}
          />
        </Box>
      </Box>
    </Box>
  );
}

const Feed = () => {
  const {
    tokens,
    loading,
    error,
    hasMore,
    page,
    handleNextPage,
    handlePreviousPage,
  } = useFetchTokens();
  const navigate = useNavigate();

  if (loading && tokens.length === 0) {
    return (
      <Box style={{ width: "100%" }}>
        <Skeleton
          variant="rectangular"
          height={150}
          style={{ marginBottom: "16px" }}
          width="100%"
        />
      </Box>
    );
  }

  if (error) {
    return <ErrorPage title={"Error Fetching data"} message={error} />;
  }

  return (
    <Box
      minHeight={"200px"}
      sx={{
        background: "rgba(0,0,0,1)",
      }}
      padding={2}
      overflow={"auto"}
    >
      <Box>
        <Grid container spacing={2}>
          {tokens.map((token, i) => {
            console.log(token);
            return (
              <Grid item xs={3} key={token._id}>
                <Card
                  sx={{
                    //   minWidth: 275,
                    //   marginBottom: 2,
                    background: "rgba(11,8,1,1)",
                    //   border: "1px solid rgba(255,255,255,.21)",
                    borderRadius: "20px",
                    // height: "210px",
                    ":hover": {
                      boxShadow: "0px 0px 10px 0px rgba(255,255,255,0.5)",
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => navigate(`/stats/${token.token}`)}

                  // onClick={() =>
                  //   navigate(`/stats/${token._id}?name=${token.token}`)
                  // }
                >
                  <CardContent>
                    <Grid container rowSpacing={0.1}>
                      <Grid item xs={12}>
                        {/* <Typography variant="body2" component="div"> */}
                        {/* Token: {shortenString(token.txid)} */}
                        {/* {JSON.stringify(token)} */}
                        {/* </Typography> */}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6">
                          Token: {token.token}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2">
                          action: {token.action}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2">
                          {/* Address: {shortenString(token.deployer)} */}
                        </Typography>
                      </Grid>{" "}
                      <Grid item xs={12}>
                        <LinearWithValueLabel
                          balance={token.minted}
                          supply={token.supply}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        display={"flex"}
                        justifyContent={"center"}
                      >
                        {/* <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => navigate(`/token/${token.token}`)}
                          disabled={token.minted === token.supply}
                        >
                          Mint!
                        </Button> */}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Box display="flex" justifyContent="center" m={2}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handlePreviousPage}
          disabled={page === 1}
        >
          Previous
        </Button>
        <Box m={1} />
        <Typography variant="h6">Page: {page}</Typography>
        <Box m={1} />
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleNextPage}
          disabled={!hasMore}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default Feed;
