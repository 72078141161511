import { AuthState, AuthAction } from "./Auth.types";

export const authReducer = (
  state: AuthState,
  action: AuthAction
): AuthState => {
    console.log(action)
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case "LOGOUT":
      return { ...state, isAuthenticated: false, user: null };
    case "OPEN":
      return { ...state, isOpen: true };
    case "CLOSE":
      return { ...state, isOpen: false };
    default:
      return state;
  }
};
