import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Alert, Button, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useAuth } from "../../context/Auth/Auth.context";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    // [theme.breakpoints.up("md")]: {
    //   width: "20ch",
    // },
  },
}));

const pages = [""];

export default function PrimarySearchAppBar() {
  const navigate = useNavigate();
  const { state, dispatch } = useAuth();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const theme = useTheme();

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      // Perform your action here
      // For example, you might want to submit the input value
      navigate("/inscription/" + e.target.value);
    }
  };
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        {state.isAuthenticated && state.user ? (
          <Button
            variant="text"
            color="secondary"
            sx={{ fontWeight: "600", fontSize: "1.1rem" }}
            onClick={() => {
              console.log("clicked");
              dispatch({
                type: "OPEN",
              });
            }}
          >
            <AccountCircleIcon color="secondary" />
            {state.user.address.slice(0, 5)}...
            {state.user.address.slice(-4)}
          </Button>
        ) : (
          <Button
            onClick={() => {
              console.log("clicked");
              dispatch({
                type: "OPEN",
              });
            }}
            disabled={state.isAuthenticated}
            variant="text"
            color="secondary"
            sx={{ fontWeight: "600", fontSize: "1.1rem" }}
          >
            <AccountCircleIcon color="secondary" /> Login
          </Button>
        )}
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" elevation={0}>
        <Toolbar>
          <Box display={"flex"} flexDirection={"column"} width={"100%"}>
            <Box width={"100%"}>
              {" "}
              <Alert severity="warning">
                This Project is still in beta, USE AT YOUR OWN RISK!!!
              </Alert>{" "}
            </Box>
            <Box m={0.2} />
            <Box
              height={"30px"}
              width={"100%"}
              display="flex"
              justifyContent={"space-between"}
            >
              {" "}
              <Button
                variant="text"
                // component="div"
                sx={{
                  // display: { xs: "none", sm: "non" },
                  color: "white",
                  fontSize: "1.3rem",
                }}
                onClick={() => navigate("/")}
              >
                ["$Dipe"]-
                <span
                  style={{
                    color: "rgb(255,162,0)",
                  }}
                >
                  Chain
                </span>{" "}
              </Button>
              <Box
                sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
              ></Box>
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <Button
                  sx={{
                    // background: "#ffff",
                    color: "#FFF",
                    border: "1px solid #FFF",

                    fontWeight: "600",
                    fontSize: "1.1rem",
                  }}
                  variant="outlined"
                  onClick={() => {
                    console.log("clicked");
                    navigate("/marketplace/all");
                  }}
                >
                  Market
                </Button>
                <Box m={0.5} />
                {state.isAuthenticated && state.user ? (
                  <Button
                    onClick={() => {
                      console.log("clicked");
                      dispatch({
                        type: "OPEN",
                      });
                    }}
                    sx={{
                      // background: "#ffff",
                      color: "#FFF",
                      border: "1px solid #FFF",

                      fontWeight: "600",
                      fontSize: "1.1rem",
                    }}
                    variant="outlined"
                  >
                    <AccountCircleIcon color="secondary" />
                    {state.user.address.slice(0, 5)}...
                    {state.user.address.slice(-4)}
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      console.log("clicked");
                      dispatch({
                        type: "OPEN",
                      });
                    }}
                    disabled={state.isAuthenticated}
                    sx={{
                      background: "#ffff",
                      color: "black",
                      "&:hover": {
                        background: "#000",
                        color: "#ffff",
                      },
                      fontWeight: "600",
                      fontSize: "1.1rem",
                    }}
                    variant="contained"
                  >
                    <AccountCircleIcon color="secondary" /> Login
                  </Button>
                )}
                <Box m={0.5} />
                <Button
                  // color="secondary"
                  sx={{
                    background: "#ffff",
                    color: "black",
                    "&:hover": {
                      background: "#000",
                      color: "#ffff",
                    },
                    fontWeight: "600",
                    fontSize: "1.1rem",
                  }}
                  variant="contained"
                  onClick={() => navigate("/token/new_token")}
                >
                  Deploy
                </Button>
              </Box>
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
